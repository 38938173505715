<template>
  <div>
    <el-dialog visible append-to-body :show-close="false" custom-class="rounded close-modal" width="600px">
      <template #title>
        <div>
          <div class="d-flex justify-content-between">
            <h2 class="title">{{ $t('requests.serviceProviderResponseModal.title') }}</h2>
            <Button type="icon" class="p-0 close-button" @click="$emit('close')">
              <CloseIcon :size="20" />
            </Button>
          </div>
          <p>
            {{ request.sourceBusiness.name }}
            <ExchangeIcon class="mx-1" />
            {{ request.targetBusiness.name }}
          </p>
        </div>
      </template>
      <div class="details text-typography-primary mb-4">
        <h3 class="mb-4">{{ $t('requests.serviceProviderResponseModal.requestDetails') }}</h3>
        <p>{{ request.details }}</p>
      </div>
      <div v-if="transformedRequest.responses?.length" class="responses">
        <div class="d-flex flex-column gap-6">
          <div v-for="(response, i) in transformedRequest.responses" :key="i">
            <div class="d-flex flex-column response">
              <div class="d-flex justify-content-between align-items-center mt-1 mb-2">
                <div class="d-flex gap-2 align-items-center">
                  <ReplyIcon width="16" height="16" class="text-typography-secondary align-self-center" />
                  <div class="d-flex rounded-pill justify-content-center align-items-center rounded-div-container">
                    <div v-if="response.createdBy.profilePictureUrl" class="rounded-pill profile-pic">
                      <img :src="response.createdBy.profilePictureUrl" :width="16" :height="16" />
                    </div>
                    <div
                      v-else
                      class="bg-primary rounded-pill d-flex justify-content-center align-items-center initials-pill"
                    >
                      <small class="text-uppercase text-white fs-bold initials-text">{{
                        response.createdBy?.firstName?.[0] + response.createdBy?.lastName?.[0]
                      }}</small>
                    </div>
                  </div>
                  <span>{{ response.createdBy?.firstName }} {{ response.createdBy?.lastName }}</span>
                  <span class="fw-bold">·</span>
                  <div class="d-flex gap-2 align-items-center">
                    <small v-if="!isNil(response.isCreatedByAdmin)">
                      {{ response.isCreatedByAdmin ? 'Clarity' : request.targetBusiness.name }}
                    </small>
                    <span class="fw-bold">·</span>
                    <small>{{ formatMsDate(response.createdAt) }}</small>
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <p v-if="response.text" class="mt-1 mb-3 text-typography-primary">
                  {{ response.text }}
                </p>
                <div v-if="response.attachments?.length" class="d-flex flex-wrap gap-2">
                  <div
                    v-for="(attachment, index) in response.attachments"
                    :key="index"
                    class="attachment d-flex align-items-center gap-2"
                    @click="showFile(response.filePathUrls[index])"
                  >
                    <PDFIcon />
                    <small>{{ attachment.match(/[^/]+$/)[0] }}</small>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="response.reject" class="reject">
              <div class="d-flex align-items-baseline gap-2 mt-1 mb-2">
                <el-tooltip
                  :content="$t('requests.serviceProviderResponseModal.invalidResponse')"
                  placement="top"
                  class="ms-1"
                  effect="dark"
                >
                  <NoticeFullIcon
                    fill="#E52044"
                    width="16"
                    height="16"
                    class="text-typography-secondary align-self-center"
                  />
                </el-tooltip>

                <div class="d-flex rounded-pill justify-content-center align-items-center rounded-div-container">
                  <div v-if="response.reject.createdBy?.profilePictureUrl" class="rounded-pill profile-pic">
                    <img :src="response.reject.createdBy.profilePictureUrl" :width="16" :height="16" />
                  </div>
                  <div
                    v-else
                    class="bg-primary rounded-pill d-flex justify-content-center align-items-center initials-pill"
                  >
                    <small class="text-uppercase text-white fs-bold initials-text">{{
                      response.reject.createdBy?.firstName?.[0] + response.reject.createdBy?.lastName?.[0]
                    }}</small>
                  </div>
                </div>
                <span v-if="!response.reject.createdBy">-</span>
                <span v-else>{{ response.reject.createdBy.firstName }} {{ response.reject.createdBy.lastName }}</span>
                <span class="fw-bold">·</span>
                <div class="d-flex gap-2 align-items-center">
                  <small>Clarity</small>
                  <span class="fw-bold">·</span>
                  <small>{{ formatMsDate(response.reject.createdAt) }}</small>
                </div>
              </div>
              <div class="d-flex">
                <p v-if="response.reject.text" class="mt-1 mb-3 text-typography-primary">
                  {{ response.reject.text }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="answer mt-4 text-typography-primary">
        <p>{{ $t('requests.closeModal.answer') }}</p>
        <el-input
          v-model="answer"
          class="details-area mt-1"
          type="textarea"
          resize="none"
          :autosize="{ minRows: 4, maxRows: 10 }"
        />
      </div>
      <Inquiries :inquiries="inquiriesWithRequestId">
        <template #title>
          <h3 class="mb-4 mt-6">{{ $t('requests.snoozeModal.inquiries') }}</h3>
        </template>
        <template #inquiryInput>
          <InquiryInput class="mt-4 mb-2" :inquiry.sync="requestInquiryInput" @create-inquiry="createInquiryHandler" />
        </template>
      </Inquiries>
      <template #footer>
        <div class="d-flex justify-content-end">
          <Button type="secondary" @click="$emit('close')">{{
            $t('requests.serviceProviderResponseModal.buttons.cancel')
          }}</Button>
          <Button :disabled="!answer?.length" @click="handleCreateRequestResponse">{{
            $t('requests.serviceProviderResponseModal.buttons.save')
          }}</Button>
        </div>
      </template>
    </el-dialog>
    <ActionsModal
      v-if="confirmCreateRequestResponseToggle"
      :toggle-dialog="confirmCreateRequestResponseToggle"
      :title="$t('requests.snoozeModal.inquiryNotSavedTitle')"
      :content="$t('requests.snoozeModal.inquiryNotSavedContent')"
      :confirm-button-text="$t('requests.snoozeModal.save')"
      :cancel-button-text="$t('requests.snoozeModal.skip')"
      dialog-type="info"
      @on-cancel="$emit('create-request-response', answer)"
      @on-close="confirmCreateRequestResponseToggle = false"
      @on-confirm="
        () => {
          createInquiryHandler();
          $emit('create-request-response', answer);
        }
      "
    />
    <el-dialog append-to-body :visible.sync="dialogVisible">
      <iframe :src="currentFileUrl" style="width: 100%; height: 50vh"></iframe>
    </el-dialog>
  </div>
</template>

<script>
import { ref, computed, getCurrentInstance, watch, toRefs } from 'vue';
import { isEmpty, isNil } from 'ramda';
import { DateTime } from 'luxon';

import { Button, ActionsModal } from '@/modules/core';
import { ExchangeIcon, CloseIcon, ReplyIcon, PDFIcon, NoticeFullIcon } from '@/assets/icons';
import { ROLE_TYPE } from '@/modules/users/constants';

import { useCreateInquiry } from '../compositions/createInquiry';
import InquiryInput from './InquiryInput';
import Inquiries from './Inquiries';
import { USER_QUERY } from '../compositions';

export default {
  components: {
    Button,
    ActionsModal,
    InquiryInput,
    Inquiries,
    ExchangeIcon,
    CloseIcon,
    ReplyIcon,
    PDFIcon,
    NoticeFullIcon,
  },
  props: {
    request: { type: Object, required: true },
  },
  setup(props) {
    const root = getCurrentInstance().proxy;
    const { mutate: createInquiry } = useCreateInquiry();

    const { request } = toRefs(props);
    const transformedRequest = ref({ ...props.request });

    watch(
      request,
      async (request) => {
        await Promise.all(
          (request.responses ?? []).map((response) =>
            root.$apollo.query({ query: USER_QUERY, variables: { id: response.createdBy.id } }).then(({ data }) => ({
              ...response,
              isCreatedByAdmin: data.user.memberships.some(({ role }) => role === ROLE_TYPE.ADMIN),
            }))
          )
        ).then((data) => (transformedRequest.value = { ...request, responses: data }));
      },
      { immediate: true, deep: true }
    );

    return {
      answer: ref(),
      requestInquiryInput: ref(),
      confirmCreateRequestResponseToggle: ref(false),
      createInquiry,
      inquiriesWithRequestId: computed(() =>
        (props.request.inquiries ?? []).map((inquiry) => ({ ...inquiry, requestId: props.request.id }))
      ),
      transformedRequest,
      currentFileUrl: ref(null),
      dialogVisible: ref(false),
      isNil,
    };
  },
  methods: {
    handleCreateRequestResponse() {
      if (!this.requestInquiryInput || isEmpty(this.requestInquiryInput)) {
        this.$emit('create-request-response', this.answer);
      } else {
        this.confirmCreateRequestResponseToggle = true;
      }
    },
    async createInquiryHandler() {
      await this.createInquiry({
        data: { content: this.requestInquiryInput },
        requestId: this.request.id,
      });
      this.requestInquiryInput = null;
    },
    formatMsDate(ms) {
      return DateTime.fromMillis(ms).toFormat('HH:mm, dd.MM.yy');
    },
    showFile(url) {
      this.currentFileUrl = url;
      this.dialogVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
$border-color: #bbbec2;

::v-deep .close-modal {
  .el-dialog__header {
    padding: 1rem;
  }

  .el-dialog__body {
    padding: 0rem 1rem 1rem 1rem;
  }

  .el-dialog__footer {
    padding: 1rem;
  }
}

.details {
  word-break: break-word;
}

.close-button {
  color: $typography-secondary;
  &:hover {
    background: #fff !important;
    color: $typography-primary !important;
  }
}

.answer {
  word-break: break-word;
}

.response {
  background: #f3f3f4;
  margin-inline-start: 1.5rem;
  position: relative;
  padding: 12px;
}

.response::before {
  content: '';
  position: absolute;
  top: -8px;
  right: 16px;
  transform: translateX(-50%) rotate(45deg);
  width: 18px;
  height: 18px;
  background-color: #f3f3f4;
  border-radius: 4px;
}

.reject {
  background: #f3f3f4;
  margin-inline-start: 1.5rem;
  position: relative;
  padding: 8px 32px 12px 12px;
}

.attachment {
  width: fit-content;
  padding: 0.25rem 0.375rem 0.25rem 0.5rem;
  border-radius: 4px;
  border: 1px solid #e3e4e6;
  cursor: pointer;
  background: #fff;
}

.rounded-div-container {
  height: 1rem;
  width: 1rem;
  box-shadow: 0 0 0 1px #d9dcde;
  position: relative;
  border-radius: 50%;
  cursor: default;
}

.initials-pill {
  height: 1rem;
  width: 1rem;
}
.initials-text {
  font-size: 0.45rem;
  line-height: 1rem;
}
.profile-pic {
  box-shadow: 0 0 0 1px #d9dcde;
  position: relative;
  border-radius: 50%;
  line-height: 1rem;
}
</style>
