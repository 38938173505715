<template>
  <div>
    <el-dialog visible append-to-body :show-close="false" custom-class="request-snooze-modal">
      <template #title>
        <div class="d-flex justify-content-between align-items-start pt-4 pb-5">
          <h2>{{ $t('requests.snoozeModal.title') }}</h2>
          <Button type="text" class="p-1 text-typography-primary action-btn" @click="$emit('close')">
            <CloseIcon />
          </Button>
        </div>
      </template>
      <div class="request-details__container p-4 d-flex text-typography-primary">
        <div class="request-details__icon">
          <BellUserIcon v-if="isRequestOverdue && isRequestSnoozedByUser" class="bell-user-icon" />
          <BellOutlinesIcon v-else-if="isRequestOverdue" class="request-icon" />
          <SnoozeIcon v-else class="request-icon" />
        </div>
        <div>
          <div class="px-3 activated-text">{{ requestActivationText }}</div>
          <div class="px-3 activated-text">{{ requestActivationTime }}</div>
        </div>
      </div>
      <div class="fw-bold pt-6 text-typography-primary">
        {{ $t('requests.snoozeModal.requestStallingDuration') }}
      </div>
      <el-radio-group v-model="stallingTime" class="d-flex flex-column mt-2">
        <el-radio v-for="value in Object.values(STALING_TIME_RADIO_STATE)" :key="value" :label="value" class="m-0 pt-2">
          <p class="d-inline-flex text-typography-primary">
            {{ $t(`requests.snoozeModal.stallingTime.${value}`) }}
          </p>
        </el-radio>
      </el-radio-group>
      <div v-if="stallingTime === STALING_TIME_RADIO_STATE.PERSONALIZED">
        <div class="fw-bold mt-3">
          {{ $t('commons.date') }}
        </div>
        <el-date-picker
          v-model="customDate"
          class="mt-1 mx-n2"
          format="dd.MM.yyyy"
          :picker-options="getDatePickerOptions()"
        />
        <div class="fw-bold mt-3">
          {{ $t('commons.hour') }}
        </div>
        <el-select
          v-model="customTime"
          class="mt-1"
          :popper-append-to-body="false"
          :placeholder="$t('commons.searchWithDots')"
        >
          <el-option v-for="time in timeArr" :key="time" :label="time" :value="time" suffix-icon />
        </el-select>
      </div>
      <div class="mt-6 text-typography-primary">
        <div class="fw-bold">
          {{ $t('requests.snoozeModal.comments') }}
        </div>
        <el-input v-model="comment" class="comment-area mt-2" type="textarea" :autosize="{ minRows: 4, maxRows: 10 }" />
      </div>
      <Inquiries :inquiries="inquiriesWithRequestId">
        <template #title>
          <h3 class="mb-4 mt-6">{{ $t('requests.snoozeModal.inquiries') }}</h3>
        </template>
        <template #inquiryInput>
          <InquiryInput class="mt-4 mb-2" :inquiry.sync="requestInquiryInput" @create-inquiry="createInquiryHandler" />
        </template>
      </Inquiries>
      <div class="d-flex justify-content-end mt-6">
        <Button type="secondary" @click="$emit('close')">{{ $t('commons.cancel') }}</Button>
        <Button type="primary" @click="handleConfirm">{{ $t('commons.save') }}</Button>
      </div>
    </el-dialog>
    <ActionsModal
      v-if="confirmSaveToggle"
      :toggle-dialog="confirmSaveToggle"
      :title="$t('requests.snoozeModal.inquiryNotSavedTitle')"
      :content="$t('requests.snoozeModal.inquiryNotSavedContent')"
      :confirm-button-text="$t('requests.snoozeModal.save')"
      :cancel-button-text="$t('requests.snoozeModal.skip')"
      dialog-type="info"
      @on-cancel="
        () => {
          requestInquiryInput = null;
          handleConfirm();
        }
      "
      @on-confirm="
        () => {
          createInquiryHandler();
          handleConfirm();
        }
      "
    />
  </div>
</template>

<script>
import { ref, computed, getCurrentInstance } from 'vue';
import { DateTime } from 'luxon';
import { isNil, isEmpty, reject, anyPass } from 'ramda';
import moment from 'moment';

import { BellOutlinesIcon, SnoozeIcon, BellUserIcon, CloseIcon } from '@/assets/icons';
import { Button, ActionsModal } from '@/modules/core';

import { STALING_TIME_RADIO_STATE } from '../../constants';
import { useCreateInquiry } from '../compositions/createInquiry';
import InquiryInput from './InquiryInput';
import Inquiries from './Inquiries';

const totalNumberOfDailyWorkHours = 18;

const timeArr = Array.from({ length: totalNumberOfDailyWorkHours }, (_, index) => {
  const hour = index + 6;
  return hour < 10 ? `0${hour}:00` : `${hour}:00`;
});

export default {
  name: 'RequestSnoozeModal',
  components: { BellOutlinesIcon, SnoozeIcon, BellUserIcon, CloseIcon, Button, InquiryInput, Inquiries, ActionsModal },
  props: {
    request: { type: Object, required: true },
  },
  emits: ['close', 'update-request', 'on-close-request'],
  setup(props) {
    const requestInquiryInput = ref();
    const confirmRequestCloseToggle = ref(false);
    const confirmSaveToggle = ref(false);
    const { $i18n } = getCurrentInstance().proxy;

    const { mutate: createInquiry } = useCreateInquiry();

    const createInquiryHandler = () => {
      if (!requestInquiryInput.value) return;

      const inquiryToCreate = {
        data: { content: requestInquiryInput.value },
        requestId: props.request.id,
      };

      createInquiry(inquiryToCreate);
      requestInquiryInput.value = null;
    };

    const isRequestOverdue = () => DateTime.fromMillis(props.request.activeAt) <= DateTime.fromJSDate(new Date());
    const isRequestSnoozedByUser = () => !isNil(props.request.activeBy);

    const getRequestActivatedText = () => {
      const isOverdue = isRequestOverdue();
      const user = props.request.activeBy;
      const userName = user ? `${user.firstName} ${user.lastName}` : '';

      const prefix = isNil(user)
        ? isOverdue
          ? $i18n.t('requests.snoozeModal.requestWasActivated')
          : $i18n.t('requests.snoozeModal.requestWillBeActivated')
        : isOverdue
        ? $i18n.t('requests.snoozeModal.requestWasActivatedByUser', { name: userName })
        : $i18n.t('requests.snoozeModal.requestWillBeActivatedByUser', { name: userName });

      return prefix;
    };

    const getRequestActivatedTime = () => {
      return `${moment(props.request.activeAt).from(new Date())}, ${new Date(props.request.activeAt).toLocaleDateString(
        $i18n.locale,
        {
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        }
      )}`;
    };

    return {
      STALING_TIME_RADIO_STATE,
      stallingTime: ref(STALING_TIME_RADIO_STATE.NO_CHANGE),
      comment: ref(props.request.comment ?? ''),
      answer: ref(props.request.answer ?? ''),
      details: ref(props.request.details ?? ''),
      customDate: ref(new Date()),
      customTime: ref(timeArr[3]),
      timeArr,
      requestInquiryInput,
      confirmSaveToggle,
      confirmRequestCloseToggle,
      createInquiryHandler,
      isRequestOverdue: isRequestOverdue(),
      isRequestSnoozedByUser: isRequestSnoozedByUser(),
      requestActivationText: getRequestActivatedText(),
      requestActivationTime: getRequestActivatedTime(),
      inquiriesWithRequestId: computed(() =>
        (props.request.inquiries ?? []).map((inquiry) => ({ ...inquiry, requestId: props.request.id }))
      ),
    };
  },
  methods: {
    getDatePickerOptions() {
      return {
        disabledDate: (date) => date < DateTime.local().startOf('day').toJSDate(),
      };
    },
    handleConfirm() {
      if (!this.requestInquiryInput) {
        const stallingDate = this.getStallingDate();
        this.$emit('update-request', {
          id: this.request.id,
          data: reject(anyPass([isEmpty, isNil]), { activeAt: stallingDate, comment: this.comment }),
        });
        this.$emit('close');
      } else {
        this.confirmSaveToggle = true;
      }
    },
    getStallingDate() {
      const nowTime = DateTime.fromJSDate(new Date());

      switch (this.stallingTime) {
        case STALING_TIME_RADIO_STATE.NO_CHANGE:
          return null;
        case STALING_TIME_RADIO_STATE.ONE_HOUR:
          return nowTime.plus({ hours: 1 }).toISO();
        case STALING_TIME_RADIO_STATE.TWO_HOURS:
          return nowTime.plus({ hours: 2 }).toISO();
        case STALING_TIME_RADIO_STATE.TOMORROW_AT_NINE:
          return nowTime.plus({ days: 1 }).set({ hour: 9 }).set({ minutes: 0 }).toISO();
        case STALING_TIME_RADIO_STATE.DAY_AFTER_TOMORROW_AT_NINE:
          return nowTime.plus({ days: 2 }).set({ hour: 9 }).set({ minutes: 0 }).toISO();
        case STALING_TIME_RADIO_STATE.NEXT_WEEK_AT_NINE:
          return nowTime.plus({ weeks: 1 }).set({ hour: 9 }).set({ minutes: 0 }).toISO();
        case STALING_TIME_RADIO_STATE.PERSONALIZED:
          return DateTime.fromJSDate(this.customDate)
            .set({ hours: this.customTime.split(':')[0] })
            .set({ minutes: this.customTime.split(':')[1] })
            .toISO();

        default:
          return null;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.action-btn {
  height: fit-content;
  &:hover {
    background: $secondary;
  }
}

:deep(.request-snooze-modal) {
  width: 500px;
  padding: 0px;
  border-radius: 8px;
  .el-dialog {
    &__body {
      padding: 0px 16px 16px;
      margin: 0px;
    }

    &__header {
      padding: 0px 16px;
    }
  }

  input.el-input__inner {
    width: 212px;
  }
  textarea {
    resize: none;
  }
}
.title-text {
  font-size: 24px;
  color: #000000;
  font-weight: $font-weight-bold;
  padding: 0px;
}

.subtitle {
  font-size: 16px;
}

.request-details {
  &__container {
    background: #fbfbfb;
    border: 1px solid #d9dcde;
    border-radius: 4px;
  }
  &__icon {
    width: 24px;
    height: 24px;
  }
}

.bell-user-icon {
  width: 36px !important;
  height: 36px !important;
  margin-top: -4px;
  margin-right: -4px;
}

.activated-text {
  word-break: keep-all;
}

.request-icon {
  width: 100%;
  height: 100%;
}
</style>
